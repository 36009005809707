<template>
  <div style="position:relative;overflow:hidden;">
    <el-cascader style="min-width:240px;width:100%;"
      :style="{ width: width }"
      :options="options"
      placeholder="请选择所在地区(选填)"
      :value="region_"
      @change="regionChange"
      :separator="' '"
    ></el-cascader> 
    <!-- <el-cascader :style="{ width: width }"
      v-model="selectedOptions"
      placeholder="请选择所在地区(选填)"
      ref="casAddress"
      :props="prop"
      @change="handleChange($event)" 
    ></el-cascader> -->
  </div>
</template>
<script>
import options from "./country-level2-data";
import { getDistrict } from "@/service/company";
export default {
  model: {
    prop: "region",
    event: "change"
  },
  props: {
    region: Object,
    width: {
      type: String,
      default: "auto"
    }
  },
  data() {
    return {
      //存放城市数据
      options: options,
      // options: [],
      prop:{
        value: 'adcode',
        label: 'name',
        children: 'cities',
        lazy: true, // 懒加载
        lazyLoad: this.lazyLoad
      },
      selectedOptions: [],
    };
  },
  computed: {
    region_: function() {
      if (this.region) {
        return [this.region.province, this.region.city];
      }
      return [];
    }
  },
  created() {
    // this.getAddress(100000);
  },
  methods: {
    getAddress(value) {
      getDistrict(value).then((rst)=> {
        rst.forEach(e => {
          if (this.region.province == e.name) {
            this.selectedOptions.push(e.adcode);
            this.getAddress(e.adcode);
          } else if (this.region.city == e.name) {
            this.selectedOptions.push(e.adcode);
            this.getAddress(e.adcode);
          } else if (this.region.county == e.name) {
            this.selectedOptions.push(e.adcode);
            this.getAddress(e.adcode);
          } else if (this.region.town == e.name) {
            this.selectedOptions.push(e.adcode);
            this.getAddress(e.adcode);
          }
        });
      })
      console.log(this.selectedOptions);
    },
    lazyLoad (node, resolve) {
      const { value, level } = node;
      console.log(value);
      setTimeout(() => {
        getDistrict(value ? value : 100000).then((rst)=> {
          for (let i in rst) {
            rst[i].value=rst[i].adcode
            rst[i].label=rst[i].name
            rst[i].leaf = level >= 3
          }
          const nodes = rst
          // console.log(nodes);
          // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          resolve(nodes);
        }).catch(err => {
            console.log(err);
            this.$message.error(err.message);
        });
      }, 100);
    },
    regionChange(region) {
      this.region.province = region[0];
      this.region.city = region[1];
      this.$emit("change", this.region);
    },
    handleChange (event) {
      let checkedNodes = this.$refs['casAddress'].getCheckedNodes();
      if (checkedNodes.length>0) {
        this.region.province = checkedNodes[0].pathLabels[0]
        this.region.city = checkedNodes[0].pathLabels[1]
        this.region.county = checkedNodes[0].pathLabels[2]
        this.region.town = checkedNodes[0].pathLabels[3]
        this.$emit("change", this.region);
      }
      // console.log("region",this.region);
      console.log("selectedOptions",this.selectedOptions);
    },
  }
};
</script>
